.text-truncate,
.text-truncate-1 {
  @include text-truncate();
}

// Paragraph truncate
@for $i from 2 through 3 {
  .text-truncate-#{$i} {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i;
  }
}

// Monospace Number
.monospace-number {
  font-variant-numeric: tabular-nums;
}

// Display static classes
.display-static-1 {
  font-size: map-get($display-font-sizes, 1);
  font-weight: $display-font-weight;
  line-height: $display-line-height;
}
