// Columns
.columns {
  column-count: var(--pr-column-count, 0);

  > * {
    break-inside: avoid;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through 4 {
      .columns#{$infix}-#{$i} {
        --pr-column-count: #{$i};
      }
    }
  }
}
