// Always visible scrollbar for Chrome
$scrollbar-size: 6px;

.visible-scrollbar {
  padding-right: $scrollbar-size;

  &::-webkit-scrollbar {
    height: $scrollbar-size;
    width: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400-rgba;
    border-radius: $scrollbar-size / 2;
  }
}
