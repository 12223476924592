// Fixed
.fixed-top,
.fixed-top-right,
.fixed-top-left,
.fixed-top-center,
.fixed-bottom,
.fixed-bottom-right,
.fixed-bottom-left,
.fixed-bottom-center,
.fixed-right,
.fixed-left,
.fixed-center {
  position: fixed !important;
}


// Absolute
.absolute,
.absolute-top,
.absolute-top-right,
.absolute-top-left,
.absolute-top-center,
.absolute-bottom,
.absolute-bottom-right,
.absolute-bottom-left,
.absolute-bottom-center,
.absolute-right,
.absolute-right-center,
.absolute-left,
.absolute-left-center,
.absolute-center {
  position: absolute !important;
}


// Sticky
.sticky-top,
.sticky-right,
.sticky-bottom,
.sticky-left {
  position: sticky !important;
}


// Relative
.relative {
  position: relative !important;
}


// Static
.static {
  position: static !important;
}


// Positions
.absolute-top,
.absolute-bottom,
.fixed-top,
.fixed-bottom,
.sticky-top,
.sticky-bottom {
  right: 0;
  left: 0;
}

.absolute-right,
.absolute-left,
.fixed-right,
.fixed-left {
  top: 0;
  bottom: 0;
}

.absolute-top,
.absolute-top-right,
.absolute-top-left,
.absolute-top-center,
.fixed-top,
.fixed-top-right,
.fixed-top-left,
.fixed-top-center,
.sticky-top {
  top: 0;
}

.absolute-bottom,
.absolute-bottom-right,
.absolute-bottom-left,
.absolute-bottom-center,
.fixed-bottom,
.fixed-bottom-right,
.fixed-bottom-left,
.fixed-bottom-center,
.sticky-bottom {
  bottom: 0;
}

.absolute-right,
.absolute-right-center,
.absolute-top-right,
.absolute-bottom-right,
.fixed-right,
.fixed-top-right,
.fixed-bottom-right,
.sticky-right {
  right: 0;
}

.absolute-left,
.absolute-left-center,
.absolute-top-left,
.absolute-bottom-left,
.fixed-left,
.fixed-top-left,
.fixed-bottom-left,
.sticky-left {
  left: 0;
}

.absolute-top-center,
.absolute-bottom-center,
.fixed-top-center,
.fixed-bottom-center {
  left: 50%;
  transform: translateX(-50%);
}

.absolute-right-center,
.absolute-left-center,
.fixed-right-center,
.fixed-left-center {
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center,
.fixed-center {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
