.trim-y {
  > :first-child {
    margin-top: 0 !important;

    *:first-child {
      margin-top: 0 !important;
    }
  }

  > :last-child {
    margin-bottom: 0 !important;

    *:last-child {
      margin-bottom: 0 !important;
    }
  }
}
