@import "functions";


/** Colors **/
$white:         #fff;
$black:         #000;

$gray-50:       #f9fafb;
$gray-100:      #f3f4f6;
$gray-200:      #e5e7eb;
$gray-300:      #d1d5db;
$gray-400:      #9ca3af;
$gray-500:      #6b7280;
$gray-600:      #4b5563;
$gray-700:      #374151;
$gray-800:      #1f2937;
$gray-900:      #111827;

$gray-50-rgba:  rgba($gray-900, .05);
$gray-100-rgba: rgba($gray-900, .1);
$gray-200-rgba: rgba($gray-900, .2);
$gray-300-rgba: rgba($gray-900, .3);
$gray-400-rgba: rgba($gray-900, .4);
$gray-500-rgba: rgba($gray-900, .5);
$gray-600-rgba: rgba($gray-900, .6);
$gray-700-rgba: rgba($gray-900, .7);
$gray-800-rgba: rgba($gray-900, .8);
$gray-900-rgba: rgba($gray-900, .9);

$pr-green-light:  #a3d783;
$pr-green:        #80ba27;
$pr-green-dark:   #023f2c;
$pr-green-darker: #0a2b1a;

$green:     #158929;
$blue:      #006bb8;
$red:       #df2020;
$yellow:    #ffbf00;

$facebook:  #3b5998;
$google:    #d44235;
$twitter:   #1da1f2;

$primary:       $pr-green;
$primary-dark:  $pr-green-dark;
$primary-light: $pr-green-light;
$secondary:     $gray-200;

$dark:          $pr-green-darker;
$light:         $gray-50;

$danger:        $red;
$info:          $blue;
$success:       $green;
$warning:       $yellow;

$theme-colors: (
  "primary":        $primary,
  "primary-dark":   $primary-dark,
  "primary-light":  $primary-light,
  "secondary":      $secondary,
  "dark":           $dark,
  "light":          $light,
  "overlay":        $gray-900,

  "danger":         $danger,
  "info":           $info,
  "success":        $success,
  "warning":        $warning,
);

$min-contrast-ratio:    2.5;

$color-contrast-dark:   $dark;
$color-contrast-light:  $white;


/** Options **/
$enable-shadows:          true;
$enable-negative-margins: true;
$enable-validation-icons: false;
$enable-smooth-scroll:    false;
$enable-grid-classes:     false;
$enable-cssgrid:          true;

$enable-dark-mode:        false;

$variable-prefix: bs-;
$prefix:          $variable-prefix;


/** Spacing **/
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.50,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.50,
);

$negative-spacers: (
  0: 0,
  1: -$spacer * 0.25,
  2: -$spacer * 0.50,
  3: -$spacer * 0.75,
  4: -$spacer,
  5: -$spacer * 1.50,
);


/** Body **/
$body-color:            $gray-800;

$body-secondary-color:  $gray-600;
$body-secondary-bg:     $gray-100;


/** Links **/
$link-color:            $blue;
$link-decoration:       none;
$link-shade-percentage: 25%;
$link-hover-color:      shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: underline;


/** Paragraphs **/
$paragraph-margin-bottom:   1em;


/** Grid breakpoints **/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1090px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1090px,
  xxl: 1400px
);


/** Grid columns **/
$grid-gutter-width:       1.5rem;


/** Components **/
$border-width:            1px;
$border-width-rem:        0.0625rem;

$component-active-color:  $white;
$component-active-bg:     theme-color("info");


/** Typography **/
$font-size-base:          1rem;
$font-size-xxl:           $font-size-base * 1.5;
$font-size-xl:            $font-size-base * 1.25;
$font-size-lg:            $font-size-base * 1.125;
$font-size-sm:            $font-size-base * 0.875;
$font-size-xs:            $font-size-base * 0.75;
$font-size-xxs:           $font-size-base * 0.625;

$font-weight-light:       300;
$font-weight-normal:      400;
$font-weight-medium:      500;
$font-weight-semi-bold:   600;
$font-weight-bold:        700;
$font-weight-extra-bold:  800;
$font-weight-heavy:       900;

$line-height-base:        1.5;
$line-height-sm:          1.25;
$line-height-lg:          2;

$h1-font-size:            $font-size-base * 2;
$h2-font-size:            $font-size-base * 1.5;
$h3-font-size:            $font-size-base * 1.25;
$h4-font-size:            $font-size-base;
$h5-font-size:            $font-size-base * 0.875;
$h6-font-size:            $font-size-base * 0.75;

$headings-margin-bottom:  0.5em;
$headings-font-weight:    bold;
$headings-line-height:    $line-height-sm;
$headings-color:          $pr-green-darker;

$display-font-sizes: (
  1: 3rem,
  2: 2.75rem,
  3: 2.5rem,
  4: 2.25rem,
);

$display-font-weight:     bold;

$lead-font-size:          $font-size-lg;

$small-font-size:         .8em;

$text-muted:              $gray-600;
$text-muted-secondary:    $gray-500;

$hr-bg-color:             currentcolor;
$hr-border-width:         0;
$hr-height:               1px;
$hr-opacity:              0.2;

$list-inline-padding:     0.25rem;

$stretched-link-pseudo-element: "before";


/** Components **/
$border-color:              $gray-300;
$border-color-translucent:  $gray-200-rgba;

$border-radius:         0.25rem;
$border-radius-xs:      $border-radius;
$border-radius-sm:      $border-radius;
$border-radius-lg:      $border-radius;
$border-radius-xl:      $border-radius;

$box-shadow:            0 1px 2px $gray-200-rgba;
$box-shadow-sm:         0 1px 0 $gray-100-rgba;
$box-shadow-lg:         0 4px 8px $gray-200-rgba;
$box-shadow-inset:      inset 0 1px 1px $gray-100-rgba;

$component-active-bg:   $link-color;

$caret-width:           0.25em;


/** Tables **/
$table-color:                 none;
$table-bg:                    none;
$table-hover-bg:              $gray-100-rgba;
$table-active-bg:             lighten($info, 60%);

$table-group-separator-color: $border-color;

$table-variants: ();


/** Buttons + Forms **/
$input-btn-padding-y:       0.375rem;
$input-btn-padding-x:       0.75rem;
$input-btn-line-height:     $line-height-base;

$input-btn-focus-width:         0.2rem;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-blur:          0;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-xs:    0.25rem;
$input-btn-padding-x-xs:    0.5rem;
$input-btn-font-size-xs:    $font-size-xs;

$input-btn-padding-y-sm:    0.375rem;
$input-btn-padding-x-sm:    0.875rem;

$input-btn-padding-y-lg:    0.5rem;
$input-btn-padding-x-lg:    1.125rem;

$input-btn-padding-y-xl:    0.75rem;
$input-btn-padding-x-xl:    1.25rem;
$input-btn-font-size-xl:    $font-size-xl;

$input-btn-border-width:    $border-width-rem;


/** Buttons **/
$button-colors: map-merge($theme-colors, (
  "dark":     $gray-700-rgba,
  "light":    $white,
  "facebook": $facebook,
  "google":   $google,
  "twitter":  $twitter
));

$btn-padding-y:           $input-btn-padding-y;
$btn-padding-x:           $input-btn-padding-x;

$btn-padding-y-xs:        $input-btn-padding-y-xs;
$btn-padding-x-xs:        $input-btn-padding-x-xs;
$btn-font-size-xs:        $input-btn-font-size-xs;
$btn-border-radius-xs:    $border-radius-xs;

$btn-padding-y-sm:        $input-btn-padding-y-sm;
$btn-padding-x-sm:        $input-btn-padding-x-sm;

$btn-padding-y-lg:        $input-btn-padding-y-lg;
$btn-padding-x-lg:        $input-btn-padding-x-lg;

$btn-padding-y-xl:        $input-btn-padding-y-xl;
$btn-padding-x-xl:        $input-btn-padding-x-xl;
$btn-font-size-xl:        $input-btn-font-size-xl;
$btn-border-radius-xl:    $border-radius-xl;

$btn-border-width:        $input-btn-border-width;

$btn-font-weight:         $font-weight-bold;
$btn-box-shadow:          0 0 transparent;
$btn-focus-box-shadow:    0 0 transparent;
$btn-active-box-shadow:   $box-shadow-inset;

$btn-primary-color:       $dark;
$btn-primary-bg:          $primary;

$btn-secondary-color:     $body-color;
$btn-secondary-bg:        $secondary;
$btn-secondary-border:    $gray-100-rgba;

$btn-link-disabled-color: $link-color;

$btn-border-shade-amount:         10%;
$btn-hover-bg-shade-amount:       10%;
$btn-hover-bg-tint-amount:        20%;
$btn-hover-border-shade-amount:   15%;
$btn-hover-border-tint-amount:    5%;
$btn-active-bg-shade-amount:      10%;
$btn-active-bg-tint-amount:       20%;
$btn-active-border-shade-amount:  20%;
$btn-active-border-tint-amount:   5%;

/** Forms **/
$input-padding-y:           $input-btn-padding-y;
$input-padding-x:           $input-btn-padding-x;
$input-line-height:         $input-btn-line-height;

$input-padding-x-xs:        $input-btn-padding-x-xs;
$input-padding-y-xs:        $input-btn-padding-y-xs;
$input-font-size-xs:        $input-btn-font-size-xs;

$input-padding-x-sm:        $input-btn-padding-x-sm;
$input-padding-y-sm:        $input-btn-padding-y-sm;

$input-padding-x-lg:        $input-btn-padding-x-lg;
$input-padding-y-lg:        $input-btn-padding-y-lg;

$input-padding-x-xl:        $input-btn-padding-x-xl;
$input-padding-y-xl:        $input-btn-padding-y-xl;
$input-font-size-xl:        $input-btn-font-size-xl;

$input-bg:                  $white;

$input-color:               $body-color;
$input-border-color:        $gray-300;
$input-border-width:        $input-btn-border-width;
$input-box-shadow:          $box-shadow-inset;

$input-focus-border-color:  $component-active-bg;
$input-focus-width:         $input-btn-focus-width;

$input-invalid-box-shadow:  0 0 0 $input-focus-width rgba($danger, 0.25);

$input-placeholder-color:   $text-muted-secondary;
$input-focus-box-shadow:    $input-btn-focus-box-shadow;

$input-height-border:       $input-border-width * 2;

$input-height:              add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-xs:           add($input-line-height * 1em, add($input-padding-y-xs * 2, $input-height-border, false));
$input-height-sm:           add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:           add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));
$input-height-xl:           add($input-line-height * 1em, add($input-padding-y-xl * 2, $input-height-border, false));

$form-check-margin-bottom:  0;

$form-check-input-border:               $border-width solid $gray-300-rgba;
$form-check-input-box-shadow:           inset 0 $border-width $border-width 0 $gray-200-rgba;
$form-check-input-focus-box-shadow:     $input-focus-box-shadow;

$form-check-input-checked-color:        $component-active-color;
$form-check-input-checked-bg-color:     $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-border;
$form-check-input-checked-bg-image:     url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#{$form-check-input-checked-color}' d='M12.6 3.68C12.81 3.84 12.94 4.04 12.98 4.3 13.03 4.56 12.98 4.8 12.82 5.02L7.85 12.11C7.69 12.31 7.48 12.44 7.24 12.48 6.99 12.53 6.76 12.48 6.55 12.32 6.42 12.23 6.09 11.89 5.54 11.32 5 10.75 4.49 10.2 4.01 9.67L3.26 8.87C3.09 8.68 3 8.45 3 8.19 3 7.92 3.1 7.69 3.29 7.51 3.48 7.32 3.7 7.23 3.96 7.24 4.22 7.24 4.43 7.35 4.61 7.55L6.98 10.07 11.3 3.89C11.46 3.69 11.67 3.56 11.92 3.52 12.16 3.47 12.39 3.52 12.6 3.68Z'/></svg>");


$form-switch-color:             $white;
$form-switch-width:             3em;
$form-switch-height:            $form-switch-width / 2;
$form-switch-padding-start:     $form-switch-width + 0.5em;
$form-switch-bg-color:          $gray-300;

$form-switch-focus-color:       $white;
$form-switch-checked-color:     $white;
$form-switch-checked-bg-color:  $component-active-bg;


$form-select-bg-size:           0.5em 1em;
$form-select-indicator-color:   $input-color;
$form-select-indicator:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 14'><path fill='#{$form-select-indicator-color}' d='M4 14L0 10L8 10L4 14Z M4 0L8 4L0 4L4 0Z'/></svg>");

$form-select-padding-y-xl:      $input-padding-y-xl;
$form-select-padding-x-xl:      $input-padding-x-xl;
$form-select-font-size-xl:      $input-font-size-xl;


$form-range-track-height:         .25rem;
$form-range-track-border-radius:  $form-range-track-height / 2;

$form-range-thumb-bg:               $white;
$form-range-thumb-border:           $border-width;
$form-range-thumb-width:            1.5rem;
$form-range-thumb-box-shadow:       0 0 0 1px $gray-300-rgba, 0 1px 2px 1px $gray-200-rgba;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $component-active-bg, $input-focus-box-shadow;


$form-icon-search:              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' width='24' viewBox='0 0 24 24'><path d='M10.5 3.75C6.77208 3.75 3.75 6.77208 3.75 10.5C3.75 14.2279 6.77208 17.25 10.5 17.25C12.3642 17.25 14.0506 16.4953 15.273 15.273C16.4953 14.0506 17.25 12.3642 17.25 10.5C17.25 6.77208 14.2279 3.75 10.5 3.75ZM2.25 10.5C2.25 5.94365 5.94365 2.25 10.5 2.25C15.0563 2.25 18.75 5.94365 18.75 10.5C18.75 12.5078 18.032 14.3491 16.8399 15.7793L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L15.7793 16.8399C14.3491 18.032 12.5078 18.75 10.5 18.75C5.94365 18.75 2.25 15.0563 2.25 10.5Z' fill='#{$input-placeholder-color}'/></svg>");


/** Z-index **/
$zindex-dropdown:           999;
$zindex-sticky:             999;
$zindex-fixed:              999;
$zindex-modal-backdrop:     999;
$zindex-modal:              999;
$zindex-popover:            999;
$zindex-tooltip:            999;
$zindex-navbar:             999;


/***** Navs *****/
$nav-link-padding-y:                      $btn-padding-y + $btn-border-width;
$nav-link-padding-x:                      $btn-padding-x + $btn-border-width;

$nav-link-padding-y-lg:                   $btn-padding-y-lg + $btn-border-width;
$nav-link-padding-x-lg:                   $btn-padding-x-lg + $btn-border-width;

$nav-link-padding-y-sm:                   $btn-padding-y-sm + $btn-border-width;
$nav-link-padding-x-sm:                   $btn-padding-x-sm + $btn-border-width;

$nav-link-color:                          $body-color;
$nav-link-hover-color:                    $dark;

// Pills
$nav-pills-link-hover-bg: $gray-100-rgba;

// Underline
$nav-underline-gap:                 unset;
$nav-underline-border-width:        .25rem;
$nav-underline-link-active-color:   var(--#{$prefix}primary);


/** Navbar **/
$navbar-padding-y:                  0;

$navbar-toggler-padding-y:          add($btn-padding-y + map-get($spacers, 2), $border-width);
$navbar-toggler-padding-x:          add($btn-padding-y + map-get($spacers, 2), $border-width);
$navbar-toggler-font-size:          $font-size-base;
$navbar-toggler-focus-width:        none;


/** Dropdowns **/
$dropdown-min-width:            auto;
$dropdown-padding-y:            $spacer / 4;
$dropdown-box-shadow:           $box-shadow;
$dropdown-divider-margin-y:     $dropdown-padding-y;

$dropdown-link-color:           $body-color;
$dropdown-link-hover-color:     $dark;

$dropdown-link-disabled-color:  $text-muted;

$dropdown-item-padding-x:       $btn-padding-x + $btn-border-width;
$dropdown-item-padding-y:       $btn-padding-y + $btn-border-width;

$dropdown-header-padding-y:     $dropdown-item-padding-y;


/** Cards **/
$card-spacer-y:       $spacer;
$card-cap-padding-y:  $card-spacer-y * .75;
$card-cap-bg:         transparent;


/** Tooltips **/
$tooltip-opacity:       1;
$tooltip-arrow-height:  0;
$tooltip-arrow-width:   0;


/** Popovers **/
$popover-font-size:       $font-size-base;
$popover-max-width:       320px;
$popover-xs-max-width:    calc(100% - #{$grid-gutter-width});

$popover-body-padding-x:  0;
$popover-body-padding-y:  0;


/** Badges **/
$badge-font-size:   0.6875rem;
$badge-padding-x:   0.375rem;
$badge-padding-y:   0.25rem;


/** Modals **/
$modal-inner-padding:             0;

$modal-dialog-margin:             0.25rem;
$modal-dialog-margin-y-sm-up:     0.5rem;

$modal-content-box-shadow-xs:     $box-shadow;
$modal-content-box-shadow-sm-up:  $box-shadow-lg;

$modal-fade-transform:            scale(0.98);


/** Progress bars **/
$progress-height:         0.5em;
$progress-font-size:      inherit;
$progress-bar-color:      $primary;
$progress-border-radius:  0.125rem;
$progress-box-shadow:     none;


/** Image thumbnails **/
$thumbnail-bg:            $white;
$thumbnail-border-width:  $border-width;


/** Breadcrumbs **/
$breadcrumb-item-padding-x: $spacer / 2;
$breadcrumb-active-color:   $body-color;
$breadcrumb-divider:        quote('\203A');


/** Alerts **/
$alert-color-scale: 60%;


/** Bootstrap **/
@import "~bootstrap/scss/variables";
@import "maps";
